import LinkButton from "./link-button"
import React from "react"
import Style from "./contact-induction.module.scss"

const contactInduction = () => (
  <section className={Style.contactInduction}>
    <p><span>システム開発・Webサイト制作のことなら</span><span>お気軽にご相談ください</span></p>
    <LinkButton to="/contact/" text="お問合せはこちらから" />
  </section>
)

export default contactInduction
